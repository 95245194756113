@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ul,
  ol {
    list-style: revert;
    padding-left: 1rem;
  }
}

body {
  font-family: "Montserrat", sans-serif;
  position: relative;
  font-size: 15px;
  line-height: 1.7;
  font-weight: 500;
}

.content {
  max-height: 0;
  overflow: hidden;
  transition: all 1s cubic-bezier(0, 1, 0, 1);
}

.fl {
  display: flex !important;
}

.content.show {
  margin-top: 10px;
  height: auto;
  max-height: 9999px;
  transition: all 1s cubic-bezier(1, 0, 1, 0);
}

.outline {
  background-color: white;
  border: green;
}

.btn_arrow {
  /* margin-left: -10px; */
  opacity: 0;
  width: 0;
  overflow: hidden;
  transition: 0.5s;
}

.btn:hover .btn_arrow {
  width: 30px;
  /* margin-left: 10px; */
  opacity: 1;
}


.blog .slick-arrow {
  border-radius: 100%;
  background-color: black;
}
.blog .slick-next {
}